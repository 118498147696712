import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import Button from 'components/buttons/Button'
import AuthTextInput from 'components/inputs/AuthTextInput'

import { isSurgicalNotesProduct } from 'lib/CheckProduct'
import validateSchema from 'lib/validators'

const schema = yup.object().shape({
  password: yup.string().required('Please enter the new password'),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Your password and confirmation password do not match'
    )
    .required('Please re-enter the new password')
})

function ResetPasswordForm({ onFormSubmit }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              component={AuthTextInput}
              name="password"
              placeholder="Enter Password"
              type="password"
              icon={isSurgicalNotesProduct() ? 'surgical_notes_lock' : 'lock'}
              showValidationMessage
            />
          </div>
          <div>
            <Field
              component={AuthTextInput}
              name="passwordConfirmation"
              placeholder="Confirm Password"
              type="password"
              icon={isSurgicalNotesProduct() ? 'surgical_notes_lock' : 'lock'}
              showValidationMessage
            />
          </div>
          <AuthFormFooter>
            <Button label="Reset Password" disabled={pristine || submitting} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

ResetPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default ResetPasswordForm
