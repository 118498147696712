import { gql } from '@apollo/client'
import * as uuid from 'uuid'
import { useContext, useMemo } from 'react'

import AppContext from 'contexts/AppContext'
import Organization from 'models/Organization'
import useMutation from 'hooks/useMutation'
import useQuery from 'hooks/useQuery'
import useSearchInput from 'hooks/useSearchInput'
import { featureTypes, useAccountFeature } from 'hooks/useFeature'
import { License } from 'models/License'
import { permissions, useAccountPermissions } from 'hooks/usePermissions'

const accountCategory = {
  PROVIDER: 'doctor',
  ADMINISTRATOR: 'administrator'
}

const organizationAuthProviders = {
  OKTA: 'Okta'
}

const accountType = {
  PROVIDER: 'Provider',
  STAFF: 'Staff'
}

const accountStatuses = Object.freeze({
  active: 'active',
  invited: 'invited',
  notInvited: 'not_invited',
  inactive: 'inactive'
})

const accountStatusColors = Object.freeze({
  active: 'accentGreen',
  invited: 'accentYellow',
  not_invited: 'accentGray',
  inactive: 'negative'
})

const accountLoginTypes = Object.freeze({
  DEFAULT: 'default',
  INTERNAL: 'internal'
})

export const ACCOUNT_REGIONS = Object.freeze(['United States', 'Canada'])
export const DB_USER_REGION_KEY = 'DB_USER_REGION'
export const regionAcronyms = Object.freeze({
  'United States': 'us',
  Canada: 'ca'
})

const isInternalLogin = account => {
  if (!account) return false

  return account.loginType === accountLoginTypes.INTERNAL
}

const isExternalLogin = account => {
  if (!account) return false

  return account.loginType === Organization.loginTypes.EXTERNAL
}

const isExternalRedirectLogin = account => {
  if (!account) return false

  return [Organization.loginTypes.SAML, Organization.loginTypes.OAUTH].includes(
    account.loginType
  )
}

const hasInternalPassword = account =>
  isInternalLogin(account) ||
  (!isExternalRedirectLogin(account) && account.type === accountType.STAFF)

const hasExternalLogin = account => {
  const { type } = account || {}

  return (
    isExternalRedirectLogin(account) ||
    (isExternalLogin(account) && type === accountType.PROVIDER)
  )
}

const ACCOUNT_SETTINGS_QUERY = gql`
  query AppQuery {
    currentAccount {
      id
      settings
    }
  }
`

const UNREAD_NOTIFICATIONS_COUNT_QUERY = gql`
  query UnreadNotificationsCountQuery {
    currentAccount {
      id
      unreadNotificationsCount
    }
  }
`

const RESOLVED_FEATURES_QUERY = gql`
  query AppQuery {
    currentAccount {
      id
      resolvedFeatures
    }
  }
`

const CURRENT_ACCOUNT_QUERY = gql`
  query AppQuery {
    currentAccount {
      id
      administrator
      alternateEmail
      alternatePhone
      category
      email
      eulaSignedAt
      externalLogin
      firstName
      fullName
      lastName
      loginType
      generatedReportUrl
      notificationDaysBeforeEvent
      phoneNumber
      practices {
        id
        name
      }
      resolvedFeatures
      resolvedSettings
      resolvedPermissions
      settings
      shareToken
      speciality
      status
      type
      externalLogin
      recordingFields
      resolvedNuanceUsername

      licenses {
        ...License_details
      }

      organization {
        ...Organization_details
      }

      role {
        id
        name
      }
    }
  }

  ${License.fragments.details}
  ${Organization.fragments.details}
`

const ACCOUNTS_INFO_QUERY = gql`
  query AccountsInfoQuery($emails: [String!]!, $input: AccountsInfoInput) {
    accountsInfo(emails: $emails, input: $input) {
      id
      defaultTab
      email
      eulaSignedAt
      externalLogin
      externalLoginUrl
      loginType
      organizationAuthProvider
      organizationId
      organizationName
      organizationDisplayName
      status
      type
    }
  }
`

const SEND_VERIFICATION_CODE_MUTATION = gql`
  mutation SendVerificationCodeMutation($id: ID!) {
    sendVerificationCode(id: $id) {
      id
      email
      eulaSignedAt
      externalLogin
      loginType
      organizationId
      organizationName
      status
      type
    }
  }
`

const ACCOUNT_VERIFICATION_MUTATION = gql`
  mutation VerifyAccountMutation($id: ID!, $input: VerifyAccountInput!) {
    verifyAccount(id: $id, input: $input) {
      id
      email
      eulaSignedAt
      externalLogin
      loginType
      organizationId
      organizationName
      status
      type
    }
  }
`

const ACCOUNT_REGISTRATION_MUTATION = gql`
  mutation RegisterAccountMutation($id: ID!, $input: RegisterAccountInput!) {
    registerAccount(id: $id, input: $input) {
      id
      email
      eulaSignedAt
      externalLogin
      loginType
      organizationId
      organizationName
      status
      type
    }
  }
`

export const UPDATE_ACCOUNT_SETTINGS_MUTATION = gql`
  mutation UpdateAccountSettingsMutation(
    $id: String!
    $input: UpdateAccountSettingsInput!
  ) {
    updateAccountSettings(accountId: $id, input: $input) {
      id
      settings
    }
  }
`

export const LIST_CONNECTED_ACCOUNTS_QUERY = gql`
  query ConnectedAccounts($filter: ConnectedAccountFilterInput!) {
    connectedAccounts(filter: $filter) {
      id
      fullName
    }
  }
`

const quickFilterPageKeys = Object.freeze({
  ON_CALL: 'on-call',
  ON_CALL_SCHEDULE: 'on-call-schedule',
  SCHEDULE_CALENDAR: 'schedule-calendar',
  CLINIC_LIST: 'schedule-clinic-list',
  SURGERY_LIST: 'schedule-surgery-list',
  ROOMS: 'schedule-rooms'
})

const useQuickFilters = pageKey => {
  const {
    currentAccount: { id }
  } = useContext(AppContext)

  const {
    data: { currentAccount: { settings } = {} } = {},
    refetch: refetchSettings
  } = useQuery(ACCOUNT_SETTINGS_QUERY, {
    variables: { id },
    update: true
  })

  const [updateAccountSettings] = useMutation(
    UPDATE_ACCOUNT_SETTINGS_MUTATION,
    { onCompleted: () => refetchSettings() }
  )
  const filters =
    (settings && settings.quickFilters && settings.quickFilters[pageKey]) || []

  const methods = useMemo(
    () => ({
      createQuickFilter(name, filter) {
        let updatedSettings = {
          ...settings,
          quickFilters: settings.quickFilters || {}
        }

        updatedSettings = {
          ...updatedSettings,
          quickFilters: {
            ...updatedSettings.quickFilters,
            [pageKey]: updatedSettings.quickFilters[pageKey]
              ? [
                ...updatedSettings.quickFilters[pageKey],
                {
                  id: uuid.v4(),
                  name,
                  filter
                }
              ]
              : [
                {
                  id: uuid.v4(),
                  name,
                  filter
                }
              ]
          }
        }

        return updateAccountSettings({
          id,
          settings: updatedSettings
        })
      },

      destroyQuickFilter(settingId) {
        const updatedSettings = {
          ...settings,
          quickFilters: {
            ...settings.quickFilters,
            [pageKey]: settings.quickFilters[pageKey].filter(
              setting => setting.id !== settingId
            )
          }
        }

        return updateAccountSettings({
          id,
          settings: updatedSettings
        })
      }
    }),
    [id, pageKey, settings, updateAccountSettings]
  )

  return [filters, methods]
}

const useSelectCreator = () =>
  useSearchInput(
    LIST_CONNECTED_ACCOUNTS_QUERY,
    search => ({
      filter: {
        search
      }
    }),
    ({ connectedAccounts = [] }) =>
      connectedAccounts.map(user => ({ label: user.fullName, value: user.id }))
  )

const useDefaultTabOptions = account => {
  const useFeatureAndPermission = (feature, permission) => {
    const canUseAccountFeature = useAccountFeature(account, feature)
    const canUseAccountPermission = useAccountPermissions(account, permission)

    return canUseAccountFeature && canUseAccountPermission
  }

  const hasViewOnCallRecords = useFeatureAndPermission(
    featureTypes.VIEW_ON_CALL_RECORDS,
    permissions.VIEW_ON_CALL_RECORDS
  )

  const hasViewOnCallAvailabilities = useFeatureAndPermission(
    featureTypes.VIEW_ON_CALL_AVAILABILITIES,
    permissions.VIEW_ON_CALL_AVAILABILITIES
  )

  const hasViewVoiceCommands = useFeatureAndPermission(
    featureTypes.VIEW_VOICE_COMMANDS,
    permissions.VIEW_VOICE_COMMANDS
  )

  const canViewAdmin = account?.administrator

  const defaultTabOptions = [
    {
      label: 'Calendar',
      value: 'schedule',
      condition: useAccountFeature(account, featureTypes.VIEW_SCHEDULE)
    },
    {
      label: 'Surgery List',
      value: 'schedule_surgeries',
      condition: useAccountFeature(
        account,
        featureTypes.VIEW_SCHEDULE_SURGERIES
      )
    },
    {
      label: 'On Call Records',
      value: 'on_call_records',
      condition: hasViewOnCallRecords
    },
    {
      label: 'On Call Calendar',
      value: 'on_call_calendar',
      condition: hasViewOnCallAvailabilities
    },
    {
      label: 'Voice Commands',
      value: 'voice_commands',
      condition: hasViewVoiceCommands
    },
    { label: 'Admin', value: 'admin', condition: canViewAdmin }
  ].filter(option => option.condition)

  return defaultTabOptions
}

export {
  CURRENT_ACCOUNT_QUERY,
  UNREAD_NOTIFICATIONS_COUNT_QUERY,
  ACCOUNTS_INFO_QUERY,
  ACCOUNT_REGISTRATION_MUTATION,
  ACCOUNT_SETTINGS_QUERY,
  ACCOUNT_VERIFICATION_MUTATION,
  RESOLVED_FEATURES_QUERY,
  SEND_VERIFICATION_CODE_MUTATION,
  accountCategory,
  accountStatuses,
  accountStatusColors,
  accountType,
  isInternalLogin,
  isExternalLogin,
  isExternalRedirectLogin,
  hasInternalPassword,
  hasExternalLogin,
  organizationAuthProviders,
  quickFilterPageKeys,
  useQuickFilters,
  useSelectCreator,
  useDefaultTabOptions
}
