import React, { useEffect } from 'react'

import isJSON from 'lib/isJSON'
import { DB_USER_REGION_KEY } from 'models/Account'

export default function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key)
    if (persistedState) {
      return isJSON(persistedState)
        ? JSON.parse(persistedState)
        : persistedState
    }

    return defaultValue
  })

  useEffect(() => {
    if (key === DB_USER_REGION_KEY) {
      const persistedValue = localStorage.getItem(key)
      if (persistedValue !== state) {
        window.localStorage.setItem(key, state)

        const event = new Event('user-region-change')
        window.dispatchEvent(event)
      }
    } else {
      window.localStorage.setItem(key, JSON.stringify(state))
    }
  }, [state, key])

  return [state, setState]
}
