import React from 'react'
import { kebabCase } from 'lodash'

import AuthLayout from 'components/layouts/AuthLayout'
import RegionSelectorMenu from 'components/menu/RegionSelectorMenu'
import usePersistedState from 'hooks/usePersistedState'
import { ACCOUNT_REGIONS, DB_USER_REGION_KEY } from 'models/Account'

function LoginLayout({ path, ...props }) {
  const subDomain = window.location.hostname
  const [userRegion, setUserRegion] = usePersistedState(
    DB_USER_REGION_KEY,
    subDomain.includes('portal-ca') ? 'Canada' : 'United States'
  )

  const regionSelectorMenuItems = ACCOUNT_REGIONS.map(region => ({
    children: region,
    handleOnClick: () => setUserRegion(region),
    key: kebabCase(region)
  }))

  const navbarItems = path.includes('/login') ? (
    <RegionSelectorMenu
      menuItems={regionSelectorMenuItems}
      currentRegion={userRegion}
    />
  ) : null

  return <AuthLayout navbarItems={navbarItems} {...props} />
}

export default LoginLayout
