import React from 'react'
import { createUseStyles } from 'react-jss'

import Hidden from 'components/base/Hidden'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'

const useStyles = createUseStyles(({ colors }) => ({
  buttons: {
    borderTop: `1px solid ${colors.secondary}`,
    padding: [ 50, 0, 10, 0 ]
  }
}))

const CURRENT_YEAR = new Date().getFullYear()

function Footer({ navItems }) {
  const classes = useStyles()

  return (
    <footer>
      <Hidden xs>
        <Text size="xSmall" weight="medium" color="primaryLight">
          *This software is Patent pending.
        </Text>
        <Text size="xSmall" weight="medium" color="primaryLight">
          Copyright © {CURRENT_YEAR} DocBuddy, Inc. All Rights Reserved.
        </Text>
      </Hidden>
      <Hidden>
        <Stack className={classes.buttons} justify="space-around">
          {navItems}
        </Stack>
      </Hidden>
    </footer>
  )
}

export default Footer
