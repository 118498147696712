import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'

import FontIcon from 'components/base/FontIcon'
import Menu from 'components/menu/Menu'
import MenuItem from 'components/menu/MenuItem'
import resolveImage from 'lib/resolveImage'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'
import { regionAcronyms } from 'models/Account'

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    transition: 'all 0.5s ease-out'
  },

  menu: {
    top: 42
  },

  menuItem: {
    '& img': {
      marginRight: 5
    },

    fontSize: 12,
    padding: [0, 10, 10, 15]
  }
})

const regionFile = currentRegion =>
  `docbuddy-${regionAcronyms[currentRegion]}.png`

function RegionSelectorMenu({ menuItems, currentRegion = 'United States' }) {
  const classes = useStyles()
  const ref = useRef(null)
  const [visible, setVisibility] = useState(false)

  const toggleMenu = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisibility(false)
    } else {
      setVisibility(!visible)
    }
  }

  useEffect(() => {
    document.addEventListener('click', toggleMenu)
    return () => {
      document.removeEventListener('click', toggleMenu)
    }
  })

  const imgSrc = regionFile(currentRegion)

  return (
    <div
      ref={ref}
      role="menu"
      tabIndex={0}
      className={classes.wrapper}
      onClick={toggleMenu}
      onKeyDown={toggleMenu}
    >
      <Stack>
        <img src={resolveImage(imgSrc)} alt={imgSrc} height={20} width={20} />
        <Stack direction="column" align="flex-start" gutter={0}>
          <Text weight="semibold" color="tertiary" transform="uppercase">
            {currentRegion}
          </Text>
          <Stack>
            <Text color="tertiary" transform="uppercase" size="xxxSmall">
              Change Region
            </Text>
            <FontIcon
              color="tertiary"
              title="Change Region"
              name={visible ? 'angle-up' : 'angle-down'}
              size="small"
            />
          </Stack>
        </Stack>
      </Stack>
      <Menu visible={visible} className={classes.menu}>
        {menuItems.map(item => (
          <MenuItem
            className={classes.menuItem}
            key={item.key}
            onClick={item.handleOnClick}
          >
            <img
              src={resolveImage(regionFile(item.children))}
              alt={regionFile(item.children)}
              height={15}
              width={15}
            />
            {item.children}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default RegionSelectorMenu
