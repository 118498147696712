import React from 'react'

import AuthLayout from 'components/layouts/AuthLayout'
import NavButton from 'components/buttons/NavButton'
import TextLink from 'components/links/TextLink'

const marketingSiteUrl = import.meta.env.VITE_MARKETING_SITE_BASE_URL

function SignupLayout(props) {
  const { isLoggedIn, ...rest } = props

  const navbarItems = (
    <>
      <TextLink
        label="About"
        href={`${marketingSiteUrl}/about-us`}
        color="light"
        weight="medium"
        size="xSmall"
        letterSpacing="normal"
      />
      <TextLink
        label="Contact"
        href={`${marketingSiteUrl}/request-a-demo`}
        color="light"
        weight="medium"
        size="xSmall"
        letterSpacing="normal"
      />
      {!isLoggedIn && <NavButton compact label="LOG IN" to="/login" />}
    </>
  )

  return <AuthLayout navbarItems={navbarItems} {...rest} />
}

export default SignupLayout
