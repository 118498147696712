import { gql } from '@apollo/client'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import dx from 'lib/dashx'
import OrganizationLoginForm from 'components/forms/OrganizationLoginForm'
import Row from 'components/base/Row'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import useClientMutation from 'hooks/useClientMutation'
import useClientQuery from 'hooks/useClientQuery'
import useMutation from 'hooks/useMutation'
import useURLParams from 'hooks/useURLParams'
import { accountType, isExternalRedirectLogin } from 'models/Account'
import { GATagConversionEvent } from 'components/analytics/GATagManager'
import { ADD_ACCOUNT_TOKEN, GET_AUTH, LOGIN } from 'client/state/auth'

export const CREATE_SESSION_MUTATION = gql`
  mutation CreateSessionMutation($input: CreateSessionInput!) {
    createSession(input: $input) {
      id
      accessToken
      dashxToken
      expiresAt
      externalToken
      status
      verificationUrl

      account {
        id
      }
    }
  }
`

function OrganizationLoginPage({ location: { state: { account } = {} } }) {
  const history = useHistory()
  const urlParams = useURLParams()
  const referrer = urlParams.get('referrer')

  const {
    data: { auth: { accountTokens = [], currentToken = '' } = {} } = {}
  } = useClientQuery(GET_AUTH)

  const [addAccountToken] = useClientMutation(ADD_ACCOUNT_TOKEN)
  const [login] = useClientMutation(LOGIN)

  const [session, setSession] = useState(null)
  const [createSession] = useMutation(CREATE_SESSION_MUTATION, {
    context: {
      includeClientType: true
    },
    onCompleted: response => {
      const createdSession = response.createSession

      if (!response.createSession) {
        history.push('/login')
        return
      }

      const {
        accessToken,
        account: { id: accountId },
        dashxToken
      } = createdSession

      setSession(createdSession)
      addAccountToken({ accountId, dashxToken, token: accessToken })
    }
  })

  useEffect(() => {
    if (
      session &&
      accountTokens?.find(token => token.accountId === session.account.id) &&
      currentToken !== session.accessToken
    ) {
      const {
        account: { id: accountId },
        status,
        dashxToken,
        externalToken,
        verificationUrl
      } = session

      if (verificationUrl !== null) {
        history.push({
          pathname: `/${externalToken}/verify`,
          state: { account, unverifiedSession: session }
        })
      } else if (status !== 'unverified') {
        dx.setIdentity(accountId, dashxToken)
        login({ accountId })
      }
    }
  }, [account, accountTokens, currentToken, session, history])

  if (!account) {
    history.push('/login')
  }

  const handleLogin = (values, ...rest) => {
    const loginParameters = {
      ...values,

      organizationId: account.organizationId,
      accountType: account.type
    }

    return createSession(loginParameters, ...rest)
  }

  const getRouteWithReferrer = route =>
    `${route}${referrer ? `?referrer=${referrer}` : ''}`

  return (
    <Fragment>
      <GATagConversionEvent />
      <AuthTitle
        data-test="organization-name"
        title={account && account.organizationDisplayName}
        to={getRouteWithReferrer('/choose-organization')}
        iconLeft="angle-left-thin"
      />
      <Row>
        <Column span={3} hiddenXs />
        <Column span={6}>
          <Card variant="secondary">
            {!isExternalRedirectLogin(account) && (
              <Tabs variant="fullWidth" inline>
                <Tab
                  active={account?.type === accountType.PROVIDER}
                  onClick={() => history.push(getRouteWithReferrer('/login'))}
                >
                  Provider
                </Tab>
                <Tab
                  active={account?.type === accountType.STAFF}
                  onClick={() => history.push(getRouteWithReferrer('/login'))}
                >
                  Staff
                </Tab>
              </Tabs>
            )}
            <OrganizationLoginForm
              account={account}
              onFormSubmit={handleLogin}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default OrganizationLoginPage
