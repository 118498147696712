import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import FormattedPhoneInput from 'components/inputs/FormattedPhoneInput'
import usePersistedState from 'hooks/usePersistedState'
import validatePhone from 'lib/validatePhone'
import validateSchema from 'lib/validators'
import {
  DB_USER_REGION_KEY,
  hasExternalLogin,
  hasInternalPassword,
  isExternalRedirectLogin,
  regionAcronyms
} from 'models/Account'

const schema = shouldCheckPassword =>
  yup.object().shape({
    firstName: yup.string().required('Please enter First Name'),
    lastName: yup.string().required('Please enter Last Name'),
    phoneNumber: yup
      .string()
      .test('valid-phone', 'Not a valid number', validatePhone),
    ...(shouldCheckPassword && {
      password: yup.string().required('Please enter the password'),
      passwordConfirmation: yup
        .string()
        .oneOf(
          [yup.ref('password'), null],
          'Your password and confirmation password do not match'
        )
        .required('Please re-enter the password')
    })
  })

function AccountRegistrationForm({ account, onFormSubmit }) {
  const { loginType } = account
  const initialValues = { externalLogin: account.externalLogin }

  const [region] = usePersistedState(DB_USER_REGION_KEY, 'United States')

  // Determine default country and dial code based on region
  const defaultCountryCode = regionAcronyms[region]

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={validateSchema(schema(hasInternalPassword(account)))}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={AuthTextInput}
            name="firstName"
            placeholder="Enter First Name"
            showValidationMessage
          />
          <Field
            component={AuthTextInput}
            name="lastName"
            placeholder="Enter Last Name"
            showValidationMessage
          />
          <Field
            component={FormattedPhoneInput}
            defaultCountryCode={defaultCountryCode}
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            showValidationMessage
            variant="large"
          />
          {hasInternalPassword(account) && (
            <div>
              <Field
                component={AuthTextInput}
                name="password"
                placeholder="Enter Password"
                type="password"
                showValidationMessage
              />
              <Field
                component={AuthTextInput}
                name="passwordConfirmation"
                placeholder="Confirm Password"
                type="password"
                showValidationMessage
              />
            </div>
          )}
          {hasExternalLogin(account) && (
            <Field
              component={AuthTextInput}
              name="externalLogin"
              placeholder={
                isExternalRedirectLogin(loginType)
                  ? 'Enter External Username'
                  : 'Enter EMR Username'
              }
              showValidationMessage
            />
          )}
          <AuthFormFooter>
            <Button label="REGISTER" />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

export default AccountRegistrationForm
