import React, { Fragment } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'

import alerts from 'constants/alerts'
import AccountRegistrationForm from 'components/forms/AccountRegistrationForm'
import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import useMutation from 'hooks/useMutation'
import { showAlertSuccess } from 'client/methods'

import { ACCOUNT_REGISTRATION_MUTATION } from 'models/Account'

function CompleteRegistrationPage({
  location: { state: { account, verificationCode } = {} }
}) {
  const history = useHistory()

  const [registerAccount] = useMutation(ACCOUNT_REGISTRATION_MUTATION, {
    onCompleted: () => {
      showAlertSuccess({ message: alerts.registration_complete })
      history.push({
        pathname: '/login'
      })
    }
  })

  if (!account || account?.status === 'active') {
    return <Redirect to="/login" />
  }

  const handleSubmit = values => {
    const params = {
      ...values,
      verificationCode,
      id: account?.id,
      timeZone: DateTime.local().timeZone
    }
    return registerAccount(params)
  }

  return (
    <Fragment>
      <AuthTitle subtitle="Setup your Account." />
      <Row>
        <Column span={3} hiddenXs />
        <Column span={6}>
          <Card variant="secondary">
            <AccountRegistrationForm
              onFormSubmit={handleSubmit}
              account={account}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default CompleteRegistrationPage
