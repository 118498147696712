import React from 'react'
import { useHistory } from 'react-router-dom'
import useFetchPage from 'hooks/useFetchPage'

import Button from 'components/buttons/Button'
import Loader from 'components/loaders/Loader'
import Markdown from 'markdown-to-jsx'
import ModalFormContent from 'components/modals/base/ModalFormContent'
import Stack from 'components/base/Stack'
import { createUseStyles } from 'react-jss'
import { logout } from 'client/methods'
import {
  BaseModal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'components/modals/base'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ typography, colors, blockColors }) => ({
  container: {
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.medium,
    fontWeight: typography.fontWeightVariants.regular,
    letterSpacing: typography.fontLetterSpacingVariants.normal,
    lineHeight: typography.lineHeightVariants.normal,

    '& a': {
      fontSize: typography.fontSizeVariants.small,
      fontWeight: typography.fontWeightVariants.medium,
      color: blockColors.freeSpeechBlue
    },

    '& p': {
      margin: [10, 0]
    },

    '& strong': {
      color: colors.tertiary,
      fontWeight: typography.fontWeightVariants.semibold
    },

    '& li::marker': {
      color: colors.tertiary,
      fontSize: 20
    }
  }
}))

const markdownOptions = {
  overrides: {
    a: props => (
      <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    ),
    ol: props => <ul {...props}>{props.children}</ul>
  }
}

function EulaModal({ onSubmit, onCancel, providerCount, ...props }) {
  const classes = useStyles()
  const history = useHistory()
  const [page, loading] = useFetchPage(
    isSurgicalNotesProduct() ? '/webviews/surgicalnotes/eula' : '/webviews/eula'
  )

  const closeModal = () => {
    logout()
    history.push('/login')
  }

  return (
    <BaseModal {...props}>
      <ModalHeader>END USER LICENSE AGREEMENT</ModalHeader>
      <ModalContent>
        <ModalFormContent>
          {loading ? (
            <Loader />
          ) : (
            <div className={classes.container}>
              <Markdown options={markdownOptions}>
                {page.blocks[0].content}
              </Markdown>
            </div>
          )}
        </ModalFormContent>
      </ModalContent>
      <ModalFooter>
        <Stack>
          <Button label="I Accept" size="large" onClick={onSubmit} />
          <Button
            label="I Decline"
            variant="outline"
            size="large"
            onClick={closeModal}
          />
        </Stack>
      </ModalFooter>
    </BaseModal>
  )
}

export default EulaModal
