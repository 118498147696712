import React from 'react'
import { Form, Field } from 'react-final-form'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'

import validateSchema from 'lib/validators'
import * as yup from 'yup'

const schema = yup.object().shape({
  verificationCode: yup.string().required('Please enter the verification code')
})

function AccountVerificationForm({ onFormSubmit }) {
  return (
    <Form
      onSubmit={onFormSubmit}
      validate={validateSchema(schema)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              component={AuthTextInput}
              name="verificationCode"
              placeholder="Enter Verification code"
              showValidationMessage
            />
          </div>
          <AuthFormFooter>
            <Button
              label="SUBMIT"
            />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

export default AccountVerificationForm
